import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { encode } from "js-base64";

import {
  FaDownload,
  FaExchangeAlt,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
  FaTags,
  FaSimCard
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiShieldUserFill } from "react-icons/ri";
import { RxReset } from "react-icons/rx";
import { SlRefresh } from "react-icons/sl";
import { LuSettings2 } from "react-icons/lu";

import { graphQLClient } from "graphql/client";
import { CLIENT_SELECT_OPTIONS_QUERY, BANK_SELECT_OPTIONS_QUERY } from "graphql/queries";


import { useCurrentUser } from "hooks/use-current-user";
import { decode } from "lib/utils";
import { formatPhoneNumber } from "lib/formatters";
import { DropdownInput, DateRangeInput, TextInput } from "components/form";


const DEFAULT_FILTER_VALUE = {
  searchType: "username",
};

const SEARCH_TYPE_OPTIONS = [
  { text: "Username", value: "username" },
  { text: "Branch", value: "branch" },
  { text: "Type", value: "type" },
  { text: "Email", value: "email" },
  { text: "Name", value: "name" }
];

export const SearchBar = ({
  isLoading = false,
  onRefresh,
  onSearch,
  onExport,
}) => {
  const { currentUser } = useCurrentUser();
  const [filters, setFilters] = useState(DEFAULT_FILTER_VALUE);
  const debouncedSearch = useDebounce(filters.search, 300);

  useEffect(() => {
    const newState = { ...filters, search: debouncedSearch };
    setFilters(newState);
    onSearch(newState);
  }, [debouncedSearch]);
  
  const handleSearchTypeChange = (value) => {
    const newState = { ...filters, searchType: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleSearchTextChange = (evt) => {
    setFilters((state) => ({ ...state, search: evt.target.value }));
  };

  const handleClearSearchText = () => {
    const newState = { ...filters, search: "" };
    setFilters(newState);
    onSearch(newState);
  };

  const handleSearchTextEnterKey = (evt) => {
    if (evt.key === "Enter") {
      onSearch(filters);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex border border-slate-200 bg-slate-50">
      
      </div>

      <div className="flex grow border border-slate-200 bg-slate-50">
        <div className="w-[220px] border border-y-0 border-l-0 border-slate-200">
          <DropdownInput
            icon={<LuSettings2 className="mr-2 text-2xl text-slate-500" />}
            options={SEARCH_TYPE_OPTIONS}
            onChange={handleSearchTypeChange}
            className={{
              button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
            }}
            value={filters.searchType}
          />
        </div>

        <div className="grow">
          <TextInput
            className={{
              input:
                "rounded-sm border-0 bg-transparent focus:bg-slate-200 focus:ring-0",
            }}
            icon={<FaSearch className="mr-2 text-base text-slate-500" />}
            iconRight={
              <MdClose
                onClick={handleClearSearchText}
                title="Clear Search"
                className="absolute top-[16px] right-2 cursor-pointer text-base text-slate-500"
              />
            }
            onChange={handleSearchTextChange}
            onKeyDown={handleSearchTextEnterKey}
            value={filters.search}
          />
        </div>
      </div>
    </div>
  );
};
