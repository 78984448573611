import clsx from "clsx";
import { useEffect, useState } from "react";

import { useCurrentUser } from "hooks/use-current-user";
import { formatDecimal, formatPhoneNumber, formatReference } from "lib/formatters";
import { time } from "lib/utils";

export const CellWithClipboard = ({ className, children, data }) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleCopyToClipboard = async (evt, data) => {
    if (evt.detail === 2) {
      setShowPopup(true)
      await navigator.clipboard.writeText(data)

      setTimeout(() => {
        setShowPopup(false)
      }, 1500);
    }
  }

  const tooltipClass = clsx('absolute bg-gray-600 w-[52px] text-white rounded-lg text-xs p-1 transition-opacity duration-300 left-1/2 transform -translate-x-1/2 transform -translate-y-7', {
    'opacity-100 visible': showPopup,
    'opacity-0 invisible': !showPopup
  })

  return (
    <td className={className} onClick={(evt) => handleCopyToClipboard(evt, data)}>
      {showPopup && (
        <div className={tooltipClass}>
          copied
          <div className="absolute z-[-1] w-4 h-4 bg-gray-600 transform rotate-45 -translate-x-2/4 bottom-0 left-1/2" />
        </div>
      )}
      {children}
    </td>
  )
}

export const Table = ({ edges, page, limit, onOpenItem }) => {
  const { currentUser } = useCurrentUser();
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  useEffect(() => {}, []);

  const handleTransactionClick = (item) => {
    if (onOpenItem) {
      onOpenItem(item);
    }
  };

  return (
    <div className="mt-4 mb-4 rounded-lg border border-gray-200">
      <table className="min-w-full divide-y-2 divide-gray-200 text-xs">
        <thead className="text-slate-500 ltr:text-left rtl:text-right">
          <tr>
            <th className="w-12 text-center">#</th>
            <th className="w-12 whitespace-nowrap px-4 py-2 text-center">
              InboxId
            </th>
            <th className="w-12 whitespace-nowrap px-4 py-2 text-center">
              TransactionId
            </th>

            <th className="w-46 whitespace-nowrap px-4 py-2 text-left">
              AccountName
            </th>
            <th className="w-46 whitespace-nowrap px-4 py-2 text-left">
              Message
            </th>

            <th className=""></th>

            <th className="w-46 whitespace-nowrap px-4 py-2 text-center">
              AccountNumber
            </th>
            <th className="w-12 whitespace-nowrap px-4 py-2 text-center">
              Type
            </th>
            <th className="w-26 whitespace-nowrap px-4 py-2 text-right">
              Amount
            </th>
            <th className="w-26 whitespace-nowrap px-4 py-2 text-right">
              Balance
            </th>
            <th className="w-22 whitespace-nowrap px-4 py-2 text-center">
              ReferenceNumber
            </th>

            <th className="w-22 whitespace-nowrap px-4 py-2 text-center">
              Status
            </th>
            <th className="w-22 whitespace-nowrap px-4 py-2 text-center">
              Agent
            </th>
            <th className="w-22 whitespace-nowrap px-4 py-2 text-right">
              InboxDate
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 font-mono text-slate-800">
          {edges.map((edge, index) => {
            return (
              <tr className="hover:bg-slate-100">
                <td className="h-12 whitespace-nowrap px-4 py-2 text-center text-2xs">
                  {(page - 1) * limit + (index + 1)}
                </td>

                <td className="h-12 whitespace-nowrap px-4 py-2 text-center text-2xs">
                  {edge.node.id}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-center text-2xs">
                  {Boolean(edge.node.transactionId) && (
                    <span className="inline-flex cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs bg-emerald-100 text-emerald-700">
                      {edge.node.transactionId}
                    </span>
                  )}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-left">
                  {edge.node.accountName}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-left">
                  {edge.node.msg}
                </td>

                <td></td>

                <CellWithClipboard className="relative whitespace-nowrap px-4 py-2 text-center cursor-pointer select-none" data={edge.node.accountNumber}>
                  {formatPhoneNumber(edge.node.accountNumber, '-')}
                </CellWithClipboard>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  {edge.node.transactionType === 'RECEIVE' && (
                    <span className="inline-flex bg-emerald-100 text-emerald-700 cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs">
                      <p className="ml-1 whitespace-nowrap">RECEIVE</p>
                    </span>
                  )}

                  {edge.node.transactionType === 'SENT' && (
                    <span className="inline-flex bg-red-100 text-red-700 cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs">
                      <p className="ml-1 whitespace-nowrap">SENT</p>
                    </span>
                  )}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-right">
                  {formatDecimal(edge.node.amount)}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-right">
                  {formatDecimal(edge.node.balance)}
                </td>

                <CellWithClipboard className="relative whitespace-nowrap px-4 py-2 text-center cursor-pointer select-none" data={edge.node.referenceNumber}>
                  {formatReference(edge.node.referenceNumber)}
                </CellWithClipboard>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  {edge.node.status === 'SUCCESS' && (
                    <span className="inline-flex bg-emerald-100 text-emerald-700 cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs">
                      <p className="ml-1 whitespace-nowrap">{edge.node.status}</p>
                    </span>
                  )}

                  {edge.node.status === 'PENDING' && (
                    <span className="inline-flex bg-purple-100 text-purple-700 cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs">
                      <p className="ml-1 whitespace-nowrap">{edge.node.status}</p>
                    </span>
                  )}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  {edge.node.agent}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-right">
                  {time(edge.node.inboxDatetime).format("MMM.DD hh:mm A")}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};
