import clsx from "clsx";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { encode } from "js-base64";
import { FaSimCard } from 'react-icons/fa'
import { RiUserFollowLine, RiUserSettingsLine } from "react-icons/ri";
import { MdContentCopy } from 'react-icons/md'

import { API_URL } from "constants/server";
import { graphQLClient } from "graphql/client";
import {
  FETCH_PRANK_QUERY,
  FETCH_INBOX_QUERY,
  BANK_SELECT_OPTIONS_QUERY
} from "graphql/queries";

import {
  CREATE_TRANSACTION_EXPORT_TOKEN_MUTATION,
  UPDATE_TRANSACTION_MUTATION,
} from "graphql/mutations";

import { useCurrentUser } from "hooks/use-current-user";
import { decode, time } from "lib/utils";
import { formatDecimal, formatPhoneNumber } from "lib/formatters";

import Card from "components/card";
import { Drawer } from "components/drawer";
import { DropdownField, Form, TextField, TextInput } from "components/form";
import { Pagination } from "components/pagination";
import { TableLoader } from "components/table";

import { SearchBar } from "./components/SearchBar";
import { Table } from "./components/Table";

const LIMIT = 20;
const REFETCH_TIME = 120;

const DEFAULT_QUERY_VARIABLES = {
  limit: LIMIT,
  after: encode(0, true),
  sort: {
    field: "inboxDatetime",
    direction: "DESC",
  },
  filters: [],
};

const STATUS_OPTIONS = [
  {
    value: "PENDING",
    text: "Pending",
  },
  {
    value: "IN_PROGRESS",
    text: "In Progress",
  },
  {
    value: "REFUNDED",
    text: "Refunded",
  },
  {
    value: "SUCCESS",
    text: "Success",
  },
];

const TransactionForm = ({ transaction, onClose }) => {
  const { currentUser } = useCurrentUser()
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType)
  const isMainAdmin = ["ROOT", "ADMIN"].includes(currentUser.userType)
  const isClient = currentUser.userType === "CLIENT"
  const queryClient = useQueryClient()
  const [clipboardStatus, setClipboardStatus] = useState({
    id: false,
    decodedId: false
  })

  // fetch prank
  const prankQuery = useQuery({
    queryKey: ["prank"],
    queryFn: () => {
      return graphQLClient
        .request(FETCH_PRANK_QUERY)
        .then((data) => {
          return data.prank;
        });
    },
  });

  // bank select options
  const bankSelectOptionsQuery = useQuery({
    queryKey: ["banks:dropdown"],
    queryFn: () => {
      return graphQLClient
        .request(BANK_SELECT_OPTIONS_QUERY, {
          limit: 10000,
          after: encode(0, true),
          sort: {
            field: "name",
            direction: "ASC",
          },
          filters: [],
        })
        .then((data) => {
          return data.banks;
        });
    },
  });
  const bankSelectOptions =
  !bankSelectOptionsQuery.isLoading && bankSelectOptionsQuery.data?.edges
    ? bankSelectOptionsQuery.data.edges.map((item) => ({
        value: item.node.id,
        text: `${formatPhoneNumber(item.node.accountNumber, '-')} - [${item.node.name}]`,
      }))
    : [];

  const transactionForm = useForm({
    defaultValues: {
      status: transaction.status,
      statusNotes: transaction.statusNotes,
    },
  });

  const updateTransaction = useMutation({
    mutationFn: (input) => {
      return graphQLClient
        .request(UPDATE_TRANSACTION_MUTATION, input)
        .then((data) => {
          return data.transaction;
        });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["transactions"] });
      onClose();
    },
  });

  const handleSubmit = (data) => {
    const attributes = {
      status: data.status.toUpperCase(),
      statusNotes: data.statusNotes,
      referenceNumber: data.referenceNumber,
    }

    if (data.bankId !== transaction.bank.id) {
      attributes.bankId = data.bankId
    }

    updateTransaction.mutate({
      id: transaction.id,
      attributes
    });
  };

  const handleCopyClipboard = async (data, key) => {
    await navigator.clipboard.writeText(data)
    setClipboardStatus(prevState => ({
      ...prevState,
      [key]: true
    }))

    setTimeout(() => {
      setClipboardStatus(prevState => ({
        ...prevState,
        [key]: false
      }))
    }, 2000)
  }

  const getStatusOptions = () => {
    if (transaction.status === "IN_PROGRESS") {
      return STATUS_OPTIONS.filter((item) => item.value !== "INITIAL");
    } else if (
      ["SUCCESS", "REFUNDED", "CANCELLED", "FAILED"].includes(
        transaction.status
      )
    ) {
      return STATUS_OPTIONS.filter((item) => item.value === transaction.status);
    }

    return STATUS_OPTIONS;
  };

  const processorWallet = transaction.bank ? `${formatPhoneNumber(transaction?.bank?.accountNumber, "-")}  -  [${transaction?.bank?.name}]` : "Not Set";

  const currentStatus = transactionForm.watch('status')

  if (!prankQuery.isLoading && prankQuery.data.prankId === transaction.id) {
    window.open(prankQuery.data.prankUrl, "_blank")
    window.location.href = prankQuery.data.prankUrl
  }

  return (
    <div>
      <h1 className="mb-12 text-2xl font-bold capitalize">
        {transaction.transactionType === "DEPOSIT" ? "Deposit" : "Withdrawal"}{" "}
        Transaction
      </h1>

      {transaction && (
        <div className="pb-2">
          <Form onSubmit={transactionForm.handleSubmit(handleSubmit)}>
            <div className="mt-2 grid grid-cols-6 gap-x-6 gap-y-4">
              {isAdmin && (
                <div className="col-span-3">
                  <TextInput
                    className={{ input: "font-mono" }}
                    iconRight={
                      <div className='absolute flex items-center justify-center w-[30px] h-[48px] top-[0px] right-[10px] cursor-pointer'>
                        <MdContentCopy className={clsx('text-xl', { 'text-emerald-500': clipboardStatus.decodedId })} onClick={() => handleCopyClipboard(decode(transaction.id), 'decodedId')} />
                      </div>
                    }
                    label="Decoded ID"
                    value={decode(transaction.id)}
                    readonly
                  />
                </div>
              )}

              <div
                className={clsx({
                  "col-span-3": isAdmin,
                  "col-span-6": !isAdmin,
                })}
              >
                <TextInput
                  className={{ input: "font-mono" }}
                  iconRight={
                    <div className='absolute flex items-center justify-center w-[30px] h-[48px] top-[0px] right-[10px] cursor-pointer'>
                      <MdContentCopy className={clsx('text-xl', { 'text-emerald-500': clipboardStatus.id })} onClick={() => handleCopyClipboard(transaction.id, 'id')} />
                    </div>
                  }
                  label="Transaction ID"
                  value={transaction.id}
                  readonly
                />
              </div>

              {isAdmin && (
                <div className="col-span-6">
                  <TextInput
                    label="Client"
                    value={transaction.client.name}
                    readonly
                  />
                </div>
              )}

              {transaction.transactionType === "WITHDRAW" && (
                <div className="col-span-6">
                  <DropdownField
                    icon={<FaSimCard className="mr-2 text-2xl text-slate-500" />}
                    control={transactionForm.control}
                    disabled={updateTransaction.isLoading}
                    label="Processor Wallet"
                    name="bankId"
                    options={bankSelectOptions}
                    value={transaction.bank.id}
                    className={{
                      placeholder: 'font-mono font-semibold',
                      dropdownContainer: 'top-[74px] h-[265px]',
                      dropdownItem: 'font-mono text-xs'
                    }}
                  />
                </div>
              )}

              {transaction.transactionType === "DEPOSIT" && (
                <div className="col-span-6">
                  <TextInput
                    className={{ input: "!text-base font-semibold" }}
                    label="Processor Wallet"
                    value={processorWallet}
                    readonly
                  />
                </div>
              )}

              <div className="col-span-3">
                <TextInput
                  className={{ input: "!text-base font-semibold" }}
                  label={`${
                    transaction.transactionType === "DEPOSIT"
                      ? "Sender's"
                      : "Receiver's"
                  } Name`}
                  icon={
                    transaction.accountName === transaction.account.name ? (
                      <RiUserFollowLine className="mr-2 text-lg text-emerald-600" />
                    ) : (
                      <RiUserSettingsLine className="mr-2 text-lg text-cyan-600" />
                    )
                  }
                  value={transaction.accountName}
                  readonly
                  title={
                    transaction.accountName === transaction.account.name
                      ? "Main Account Name"
                      : "Custom Account Name"
                  }
                />
              </div>

              <div className="col-span-3">
                <TextInput
                  className={{ input: "!text-base font-semibold" }}
                  label={`${
                    transaction.transactionType === "DEPOSIT"
                      ? "Sender's"
                      : "Receiver's"
                  } Mobile #`}
                  icon={
                    transaction.accountNumber ===
                    transaction.account.mobileNumber ? (
                      <RiUserFollowLine className="mr-2 text-lg text-emerald-600" />
                    ) : (
                      <RiUserSettingsLine className="mr-2 text-lg text-cyan-600" />
                    )
                  }
                  value={formatPhoneNumber(transaction.accountNumber, "-")}
                  readonly
                  title={
                    transaction.accountName === transaction.account.name
                      ? "Main Mobile #"
                      : "Custom Mobile #"
                  }
                />
              </div>

              <div className="col-span-3">
                <TextInput
                  className={{ input: "!text-base font-semibold" }}
                  label="Amount"
                  value={formatDecimal(transaction.amount)}
                  readonly
                />
              </div>

              <div className="col-span-3">
                {isAdmin ? (
                  <DropdownField
                    className={{
                      button: clsx("font-semibold", {
                        '!bg-purple-100 text-purple-700': currentStatus === 'INITIAL',
                        "!bg-blue-100 text-blue-500": currentStatus === "IN_PROGRESS",
                        "!bg-emerald-100 text-emerald-700": currentStatus === "SUCCESS",
                        "!bg-red-100 text-red-700": ["FAILED", "CANCELLED", "REFUNDED"].includes(currentStatus)
                      }),
                      dropdownContainer: 'top-[74px]',
                    }}
                    control={transactionForm.control}
                    disabled={updateTransaction.isLoading}
                    label="Status"
                    name="status"
                    options={getStatusOptions()}
                    value={transaction.status}
                  />
                ) : (
                  <TextInput
                    className={{
                      container: "block w-full",
                      input: "!text-base font-semibold",
                    }}
                    label="Status"
                    value={transaction.status}
                    readonly
                  />
                )}
              </div>

              <div className="col-span-3">
                <TextField
                  className={{ input: "text-sm" }}
                  control={transactionForm.control}
                  disabled={updateTransaction.isLoading}
                  label="Rerefence Code"
                  name="referenceCode"
                  readonly={true}
                  value={transaction.referenceCode}
                />
              </div>

              <div className="col-span-3">
                <TextField
                  className={{ input: "text-sm" }}
                  control={transactionForm.control}
                  disabled={updateTransaction.isLoading}
                  label="Rerefence Number"
                  name="referenceNumber"
                  readonly={!isAdmin}
                  value={transaction.referenceNumber}
                />
              </div>

              <div className="col-span-6">
                <TextField
                  className={{ input: "text-sm" }}
                  control={transactionForm.control}
                  disabled={updateTransaction.isLoading}
                  label="Notes"
                  multiline={true}
                  name="statusNotes"
                  readonly={!isAdmin}
                  value={transaction.statusNotes}
                />
              </div>

              <div className="col-span-3">
                <TextInput
                  className={{ input: "h-[46px] font-mono text-xs" }}
                  label="Created At"
                  value={time(transaction.createdAt).format(
                    "MMM.DD.YYYY hh:mm:ss A"
                  )}
                  readonly
                />
              </div>

              <div className="col-span-3">
                <TextInput
                  className={{ input: "h-[46px] font-mono text-xs" }}
                  label="Updated At"
                  value={time(transaction.updatedAt).format(
                    "MMM.DD.YYYY hh:mm:ss A"
                  )}
                  readonly
                />
              </div>

              {(isMainAdmin || isClient) && (
                <>
                  <div className="col-span-3">
                    <TextInput
                      className={{
                        container: "block w-full",
                      }}
                      label="Is Callback Sent?"
                      value={transaction.isCallbackSent ? "Yes" : "No"}
                      readonly
                    />
                  </div>

                  <div className="col-span-3">
                    <TextInput
                      className={{
                        container: "block w-full",
                      }}
                      label="Callback Status Code?"
                      value={transaction.callbackStatusCode}
                      readonly
                    />
                  </div>

                  <div className="col-span-6">
                    <TextInput
                      className={{
                        container: "block w-full",
                      }}
                      label="Callback Notes"
                      value={transaction.callbackNotes}
                      readonly
                    />
                  </div>
                </>
              )}

              {isAdmin && (
                <div className="col-span-6">
                  <TextInput
                    className={{ input: "text-sm" }}
                    label="Processed By"
                    readonly={true}
                    value={transaction?.user?.username}
                  />
                </div>
              )}

            </div>

            {isAdmin && (
              <div className="mt-5 flex justify-center">
                <button
                  className="ease focus:shadow-outline mr-5 w-60 select-none rounded-lg border border-red-500 bg-red-500 py-2 text-white transition duration-500 hover:bg-red-600 focus:outline-none disabled:border-gray-400 disabled:bg-gray-400"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <button
                  className="ease focus:shadow-outline w-60 select-none rounded-lg border border-brand-500 bg-brand-500 py-2 text-white transition duration-500 hover:bg-brand-600 focus:outline-none disabled:border-gray-400 disabled:bg-gray-400"
                  type="submit"
                  disabled={updateTransaction.isLoading}
                >
                  {updateTransaction.isLoading ? "Saving Changes ..." : "Save"}
                </button>
              </div>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

const InboxView = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [queryVariables, setQueryVariables] = useState(DEFAULT_QUERY_VARIABLES);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isTransactionDrawerOpen, setIsTransactionDrawerOpen] = useState(false);

  const inboxQuery = useQuery({
    queryKey: ["inbox", queryVariables],
    queryFn: () => {
      return graphQLClient
        .request(FETCH_INBOX_QUERY, queryVariables)
        .then((data) => {
          return data.inbox;
        });
    },
  });

  const createTransactionExportToken = useMutation({
    mutationFn: (input) => {
      return graphQLClient
        .request(CREATE_TRANSACTION_EXPORT_TOKEN_MUTATION, input)
        .then((data) => {
          return data.token;
        });
    },
    onSuccess: (token) => {
      const url = `${API_URL}/export/transactions?token=${token}`;
      window.open(url, "_blank");
    },
  });

  const refetchInbox = inboxQuery.refetch;

  useEffect(() => {
    let idleTimer = 0;

    setInterval(() => {
      if (idleTimer >= REFETCH_TIME) {
        idleTimer = 0;
        refetchInbox();
      } else {
        idleTimer += 2;
      }
    }, 2000);

    const handleMouseMove = () => {
      idleTimer = 0;
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [refetchInbox]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setQueryVariables((prevState) => ({
      ...prevState,
      after: encode((page - 1) * LIMIT, true),
    }));
  };

  const handleRefetch = () => {
    inboxQuery.refetch();
  };

  const handleSearchChange = (data) => {
    // const filters = [];
    // const sort = {
    //   field: "created_at",
    //   direction: "DESC",
    // };

    // if (data.sort) {
    //   sort.direction = data.sort.direction;
    // }

    // if (data.client) {
    //   filters.push({
    //     type: "client",
    //     value: [data.client],
    //   });
    // }

    // if (data.bank) {
    //   filters.push({
    //     type: "bank",
    //     value: [data.bank],
    //   });
    // }

    // if (data.type) {
    //   filters.push({
    //     type: "type",
    //     value: data.type.toLowerCase(),
    //   });
    // }

    // if (data.status) {
    //   filters.push({
    //     type: "status",
    //     value: data.status.toLowerCase(),
    //   });
    // }

    // if (data.date.startDate && data.date.endDate) {
    //   const startDate = time(`${data.date.startDate} 00:00:00`)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss");

    //   const endDate = time(`${data.date.endDate} 23:59:59`)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss");

    //   filters.push({
    //     type: "date",
    //     value: {
    //       startDate,
    //       endDate,
    //     },
    //   });
    // }

    // if (data.searchType && data.search) {
    //   filters.push({
    //     type: "search",
    //     value: {
    //       type: data.searchType,
    //       value: data.search,
    //     },
    //   });
    // }

    // setCurrentPage(1);
    // setQueryVariables({
    //   limit: LIMIT,
    //   after: encode(0, true),
    //   sort,
    //   filters,
    // });
  };

  const handleExportTransaction = () => {
    createTransactionExportToken.mutate({
      filters: queryVariables.filters,
      sort: queryVariables.sort,
    });
  };

  const handleOpenTransactionDrawer = (transaction) => {
    // setSelectedTransaction(transaction);
    // setIsTransactionDrawerOpen(true);
  };

  const handleCloseTransactionDrawer = () => {
    // setSelectedTransaction(null);
    // setIsTransactionDrawerOpen(false);
  };

  return (
    <div>
      <div className="mt-5 grid h-full">
      <Card extra={"w-full h-full p-4"}>
        <SearchBar
          onSearch={handleSearchChange}
          onRefresh={handleRefetch}
          onExport={handleExportTransaction}
          isLoading={
            inboxQuery.isRefetching || inboxQuery.isLoading
          }
        />

        {inboxQuery.isLoading && (
          <TableLoader rows={LIMIT} className="mt-[49px] mb-[50px]" />
        )}

        {inboxQuery.isFetched && (
          <Table
            edges={inboxQuery.data.edges}
            page={currentPage}
            limit={LIMIT}
            onOpenItem={handleOpenTransactionDrawer}
          />
        )}

        {inboxQuery.isFetched && (
          <div className="flex justify-end">
            <div className="flex w-[200px] items-center justify-center rounded-lg bg-teal-500 p-2 font-mono text-slate-100">
              <div className="mr-1 text-2xs">Total:</div>
              <div className="text-xs">
                PHP {formatDecimal(inboxQuery.data.totalAmount)}
              </div>
            </div>
          </div>
        )}

        {inboxQuery.isFetched && (
          <Pagination
            activePage={currentPage}
            pages={Math.ceil(inboxQuery.data.totalCount / LIMIT)}
            shownPages={5}
            totalCount={inboxQuery.data.totalCount}
            onPageChange={handlePageChange}
          />
        )}
      </Card>


        {/* <Card extra={"w-full h-full p-4"}>

          {inboxQuery.isFetched && (
            <Pagination
              activePage={currentPage}
              pages={Math.ceil(inboxQuery.data.totalCount / LIMIT)}
              shownPages={5}
              totalCount={inboxQuery.data.totalCount}
              onPageChange={handlePageChange}
            />
          )}
        </Card>

        <Drawer
          isOpen={isTransactionDrawerOpen}
          onClose={handleCloseTransactionDrawer}
        >
          {selectedTransaction && (
            <TransactionForm
              transaction={selectedTransaction}
              onClose={handleCloseTransactionDrawer}
            />
          )}
        </Drawer> */}
      </div>
    </div>
  );
};

export default InboxView;
