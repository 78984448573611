import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { encode } from "js-base64";

import { graphQLClient } from "graphql/client";
import { FETCH_USERS_QUERY } from "graphql/queries";

import Card from "components/card";
import { TableLoader } from "components/table";

import { SearchBar } from "./components/SearchBar";
import { Table } from "./components/Table";


const LIMIT = 25;

const DEFAULT_QUERY_VARIABLES = {
  limit: LIMIT,
  after: encode(0, true),
  sort: {
    field: "id",
    direction: "DESC",
  },
  filters: [],
};


const AccountsView = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [queryVariables, setQueryVariables] = useState(DEFAULT_QUERY_VARIABLES);

  const usersQuery = useQuery({
    queryKey: ["users", queryVariables],
    queryFn: () => {
      return graphQLClient
        .request(FETCH_USERS_QUERY, queryVariables)
        .then((data) => {
          return data.users;
        });
    },
  });

  const handleSearchChange = (data) => {
    const filters = [];
    const sort = {
      field: "id",
      direction: "DESC",
    };

    if (data.searchType && data.search) {
      filters.push({
        type: "search",
        value: {
          type: data.searchType,
          value: data.search,
        },
      });
    }

    setCurrentPage(1);
    setQueryVariables({
      limit: LIMIT,
      after: encode(0, true),
      sort,
      filters,
    });
  }

  return (
    <div>
      <div className="mt-5 grid h-full">
        <Card extra={"w-full h-full p-4"}>
          <SearchBar
            onSearch={handleSearchChange}
            isLoading={
              usersQuery.isRefetching || usersQuery.isLoading
            }
          />

          {usersQuery.isLoading && (
            <TableLoader rows={LIMIT} className="mt-[49px] mb-[50px]" />
          )}

          {usersQuery.isFetched && (
            <Table
              edges={usersQuery.data.edges}
              page={currentPage}
              limit={LIMIT}
              onTransactionClick={() => {}}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default AccountsView;
