import { useQuery } from '@tanstack/react-query'

// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdArrowCircleUp, MdArrowCircleDown } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
// import { columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import tableDataComplex from "./variables/tableDataComplex.json";

import { graphQLClient } from 'graphql/client'
import { FETCH_DASHBOARD_QUERY } from 'graphql/queries'
import { formatDecimal } from 'lib/formatters'

const Dashboard = () => {
  const transactionsQuery = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => {
      return graphQLClient.request(FETCH_DASHBOARD_QUERY).then(data => {
        return data.dashboard
      })
    }
  })

  if (transactionsQuery.isLoading) {
    return null
  }

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"All Transactions"}
          subtitle={`₱${formatDecimal(transactionsQuery.data.totalTransaction)}`}
        />
        <Widget
          icon={<MdArrowCircleUp className="h-6 w-6" />}
          title={"Deposits"}
          subtitle={`₱${formatDecimal(transactionsQuery.data.totalDeposit)}`}
        />
        <Widget
          icon={<MdArrowCircleDown className="h-7 w-7" />}
          title={"Withdrawals"}
          subtitle={`₱${formatDecimal(transactionsQuery.data.totalWithdraw)}`}
        />
        <Widget
          icon={<RiMoneyDollarCircleFill className="h-7 w-7" />}
          title={"Balance"}
          subtitle={`₱${formatDecimal(transactionsQuery.data.totalBalance)}`}
        />
      </div>

      {/* Charts */}
      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}
      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
          />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
