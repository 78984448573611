import { useQuery } from "@tanstack/react-query";
import Client from "./components/Client";

import { graphQLClient } from "graphql/client";
import { FETCH_CLIENTS_QUERY } from "graphql/queries";

const ClientView = () => {
  const clientsQuery = useQuery({
    queryKey: ["clients"],
    queryFn: () => {
      return graphQLClient.request(FETCH_CLIENTS_QUERY).then((data) => {
        return data.clients;
      });
    },
  });

  if (clientsQuery.isLoading) {
    return null;
  }

  const clients = clientsQuery.data.edges;

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        {clients.map((client) => (
          <div key={client.node.id} className="col-span-4 lg:!mb-0">
            <Client client={client.node} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientView;
