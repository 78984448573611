import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";

import { graphQLClient } from "graphql/client";
import { FETCH_BANKS_QUERY } from "graphql/queries";

import Card from "components/card";
import { Spinner } from "components/loader";
import { formatPhoneNumber } from "lib/formatters";
import { time } from "lib/utils";

const BanksView = () => {
  const banksQuery = useQuery({
    queryKey: ["banks"],
    queryFn: () => {
      return graphQLClient.request(FETCH_BANKS_QUERY).then((data) => {
        return data.banks;
      });
    },
  });

  if (banksQuery.isLoading) {
    return <Spinner />;
  }

  const banks = banksQuery.data.edges;

  return (
    <div className="mt-5 grid h-full">
      <Card extra={"w-full h-full p-4"}>
        <div className="flex justify-end py-4">
          <table className="min-w-full divide-y-2 divide-gray-200 text-xs">
            <thead className="text-slate-500 ltr:text-left rtl:text-right">
              <tr>
                <th className="w-12 text-center">#</th>

                <th className="w-12 whitespace-nowrap px-4 py-2 text-left">
                  BankID
                </th>

                <th className="w-32 whitespace-nowrap px-4 py-2 text-left">
                  Name
                </th>

                <th className="w-32 whitespace-nowrap px-4 py-2 text-left">
                  Account #
                </th>

                <th className="whitespace-nowrap px-4 py-2 text-left text-left">
                  Assigned Agent
                </th>

                <th className="w-24 whitespace-nowrap px-4 py-2 text-center">
                  Enabled
                </th>

                <th className="w-28 whitespace-nowrap py-2 pl-1 pr-4 text-right">
                  Created
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 font-mono text-slate-800">
              {banks.map((bank, index) => (
                <tr key={bank.node.id}>
                  <td className="whitespace-nowrap px-4 py-3 text-center">
                    {index + 1}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2">
                    {bank.node.id}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2">
                    {bank.node.name}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2">
                    {formatPhoneNumber(bank.node.accountNumber, "-")}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2 text-left">
                    {bank.node.agents.map((agent) => {
                      return (
                        <span
                          key={`assigned-agent-${agent.id}`}
                          className="inline-flex items-center justify-center rounded-full bg-green-100 px-2.5 py-0.5 text-2xs"
                        >
                          {`(${agent.id}) ${agent.username}`}
                        </span>
                      );
                    })}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2 text-center">
                    <span
                      className={clsx(
                        "inline-flex cursor-pointer items-center justify-center rounded-full px-2.5 py-0.5 text-2xs",
                        {
                          "bg-emerald-100 text-emerald-700":
                            bank.node.isEnabled,
                          "bg-amber-100 text-amber-700": !bank.node.isEnabled,
                        }
                      )}
                    >
                      <p className="whitespace-nowrap">
                        {bank.node.isEnabled ? "Yes" : "No"}
                      </p>
                    </span>
                  </td>

                  <td className="whitespace-nowrap px-2 py-2 text-right text-2xs">
                    {time().to(time(bank.node.createdAt))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default BanksView;
