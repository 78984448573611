import clsx from "clsx";

export const SelectInput = ({
  className,
  disabled = false,
  id,
  icon,
  label,
  name,
  placeholder = "",
  options,
  readonly = false,
  value,
  ...props
}) => {
  const inputId = Boolean(id) ? id : name;
  const hasPlaceholder = Boolean(placeholder);
  const hasIcon = Boolean(icon);

  return (
    <div className={clsx(className?.container, "text-gray-600")}>
      {Boolean(label) && (
        <label
          htmlFor={inputId}
          className={clsx(
            className?.label,
            "mb-2 block text-xsfont-semibold dark:text-white"
          )}
        >
          {label}
        </label>
      )}

      <div className={clsx(className?.inputContainer, "relative")}>
        {hasIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {icon}
          </div>
        )}

        <select
          className={clsx(
            className?.input,
            "block bg-slate-50 w-full h-12 px-4 py-3 border border-slate-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500",
            {
              "pl-10": hasIcon,
            }
          )}
          disabled={disabled}
          id={inputId}
          value={value}
          {...props}
        >
          {hasPlaceholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option
              key={`${name}-option--${option.value}`}
              value={option.value}
            >
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
