import clsx from "clsx";

export const TextInput = ({
  className,
  disabled = false,
  hasClipboard = false,
  id,
  icon,
  iconRight,
  label,
  multiline = false,
  name,
  placeholder = "",
  readonly = false,
  rows = 4,
  value,
  ...props
}) => {
  const inputId = Boolean(id) ? id : name
  const hasIcon = Boolean(icon)
  const isDisabled = disabled || readonly

  return (
    <div className={clsx(className?.container, "text-gray-600")}>
      {Boolean(label) && (
        <label
          htmlFor={inputId}
          className={clsx(
            className?.label,
            "mb-2 block text-xs font-semibold dark:text-white"
          )}
        >
          {label}
        </label>
      )}

      <div className={clsx(className?.inputContainer, "relative")}>
        {hasIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {icon}
          </div>
        )}

        {multiline ? (
          <textarea
            className={clsx(
              className?.input,
              "block w-full rounded-lg border border-slate-200 bg-gray-50 px-4 py-2.5 text-sm focus:border-blue-500 focus:ring-blue-500",
              {
                "pl-10": hasIcon,
                "bg-slate-50": !isDisabled,
                "cursor-not-allowed bg-slate-100": isDisabled,
              }
            )}
            id="message"
            placeholder={placeholder}
            readOnly={readonly}
            rows={rows}
            value={value || ""}
            {...props}
          />
        ) : (
          <input
            className={clsx(
              className?.input,
              "block h-12 w-full rounded-lg border border-slate-200 px-4 py-3 text-sm focus:border-blue-500 focus:ring-blue-500",
              {
                "pl-10": hasIcon,
                "bg-slate-100": !isDisabled && !readonly,
                "cursor-not-allowed bg-slate-200": isDisabled || readonly,
              }
            )}
            disabled={disabled}
            id={inputId}
            name={name}
            placeholder={placeholder}
            readOnly={readonly}
            type="text"
            value={value || ""}
            {...props}
          />
        )}

        {iconRight}
      </div>
    </div>
  );
};
