import { GraphQLClient } from "graphql-request";
import { API_URL } from "constants/server";

const getHeaders = () => {
  const headers = {};
  const token = localStorage.getItem("token");

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

export const graphQLClient = new GraphQLClient(`${API_URL}/graphql`, {
  headers: getHeaders(),
});
