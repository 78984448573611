import React, { useState } from "react";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdFirstPage,
  MdLastPage,
} from "react-icons/md";
import clsx from "clsx";

const Page = ({ children, onClick }) => {
  return (
    <li>
      <button
        href="#"
        className="inline-flex h-8 w-8 items-center justify-center rounded border border-gray-100"
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  );
};

export const Pagination = ({
  activePage,
  pages,
  onPageChange,
  totalCount,
  shownPages = 5,
}) => {
  const [pageBatch, setPageBatch] = useState(
    Math.ceil(activePage / shownPages)
  );
  const pagesElements = [];

  const handleFirstPageClick = () => {
    setPageBatch(1);
    onPageChange(1);
  };

  const handlePrevPageClick = () => {
    if (pageBatch > 1) {
      const page = (pageBatch - 2) * shownPages + 1;

      setPageBatch((prevState) => prevState - 1);
      onPageChange(page);
    }
  };

  const handleNextPageClick = () => {
    if (pageBatch < Math.ceil(pages / shownPages)) {
      const page = pageBatch * shownPages + 1;

      setPageBatch((prevState) => prevState + 1);
      onPageChange(page);
    }
  };

  const handleLastPageClick = () => {
    setPageBatch(Math.ceil(pages / shownPages));
    onPageChange(pages);
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  for (let i = 0; i < shownPages; i++) {
    const pageNumber = i + 1 + shownPages * (pageBatch - 1);

    if (pageNumber <= pages) {
      pagesElements.push(
        <li key={`page-${pageNumber}`}>
          <button
            className={clsx(
              "block h-8 w-8 rounded border border-gray-100 text-center leading-8",
              {
                "bg-brand-500 text-white": pageNumber === activePage,
              }
            )}
            onClick={() => handlePageClick(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      );
    }
  }

  return (
    <ol className="flex justify-center gap-1 text-xs text-slate-600">
      <Page onClick={handleFirstPageClick}>
        <MdFirstPage />
      </Page>
      <Page onClick={handlePrevPageClick}>
        <MdNavigateBefore />
      </Page>

      {pagesElements}

      <Page onClick={handleNextPageClick}>
        <MdNavigateNext />
      </Page>
      <Page onClick={handleLastPageClick}>
        <MdLastPage />
      </Page>

      <li className="font-mono text-2xs">
        <span className="leading-8">
          {pages} page{pages > 1 ? "s" : ""} | {totalCount} item
          {totalCount > 1 ? "s" : ""}
        </span>
      </li>
    </ol>
  );
};
