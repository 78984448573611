import { gql } from "graphql-request";

export const LOGIN_MUTATION = gql`
  mutation Loginmutation($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

export const UPDATE_TRANSACTION_MUTATION = gql`
  mutation UpdateTransactionMutation(
    $id: ID!
    $attributes: UpdateTransactionAttributes!
  ) {
    transaction: updateTransaction(id: $id, attributes: $attributes) {
      id
      transactionType
      status
      statusNotes
      amount
      accountName
      accountNumber
      isCallbackSent
      callbackStatusCode
      callbackNotes
      createdAt
      updatedAt

      account {
        id
        name
        mobileNumber
      }

      client {
        id
        name
      }
    }
  }
`;

export const CREATE_TRANSACTION_EXPORT_TOKEN_MUTATION = gql`
  mutation CreateTransactionExportToken(
    $filters: [FilterInput!]
    $sort: SortInput
  ) {
    token: createTransactionExportToken(filters: $filters, sort: $sort)
  }
`;
