import clsx from 'clsx'
import React, { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'

import { sizes, styles } from './styles'

const selectStyles = {
  optionsContainer:
    'absolute z-10 bg-white max-h-60 w-full mt-1 py-1 rounded-md text-base font-mono shadow-lg ring-1 ring-black ring-opacity-5 text-gray-400 overflow-auto scrollbar-thumb-slate-600 scrollbar-track-slate-700 scrollbar-thin focus:outline-none sm:text-sm'
}

export const ComboBoxInput = ({
  size = 'base',
  name,
  label,
  icon,
  options = [],
  value,
  onChange,
  style
}) => {
  const defaultOption =
    options.find((option) => option.value === value) || null
  const [selectedOption, setSelectedOption] =
    useState(defaultOption)
  const [query, setQuery] = useState('')

  const handleChange = (option) => {
    setSelectedOption(option)

    if (onChange) {
      onChange(option.value)
    }
  }

  const filteredOptions =
    query === ''
      ? options
      : options.filter((item) =>
        item.text
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <div className={clsx(styles.root, style?.root)}>
      <Combobox as="div" value={selectedOption} onChange={handleChange}>
        {label && (
          <Combobox.Label className={styles.label}>{label}</Combobox.Label>
        )}

        <div className={clsx('relative mt-2', style?.inputContainer)}>
          {icon}

          <Combobox.Input
            className={clsx(styles.input, sizes[size], style?.input)}
            displayValue={(option) => option?.text}
            onChange={(event) => setQuery(event.target.value)}
          />

          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-600"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredOptions.length > 0 && (
            <Combobox.Options
              className={clsx(
                selectStyles.optionsContainer,
                style?.optionsContainer
              )}
            >
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={`${name}-option-${option.id}`}
                  value={option}
                  className={({ active }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-3 pr-9 text-xs text-gray-600',
                      {
                        'bg-slate-200': active
                      }
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={clsx('ml-3 truncate')}
                        >
                          {option.text}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={clsx(
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon
                            className={clsx('h-5 w-5 text-sky-400', {
                              '': active
                            })}
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  )
}
