import { Controller } from "react-hook-form";
import { ComboBoxInput } from "./ComboBoxInput";

export const ComboBoxField = ({
  control,
  label,
  name,
  options,
  placeholder,
  value,
  icon,
  style
}) => {
  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      render={({ field }) => {
        return (
          <ComboBoxInput
            style={style}
            name={field.name}
            label={label}
            onChange={field.onChange}
            options={options}
            placeholder={placeholder}
            value={field.value}
          />
        );
      }}
    />
  );
};
