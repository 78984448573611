import clsx from "clsx";
import { useClickAway } from "@uidotdev/usehooks";
import { createPortal } from "react-dom";
import { CgClose } from "react-icons/cg";

export const Drawer = ({ isOpen, onClose, children }) => {
  const backdropPortal = document.getElementById("backdrop-portal")

  const drawerRef = useClickAway(() => {
    onClose()
  })

  const renderBackDrop = () => {
    if (!isOpen || !backdropPortal) {
      document.body.classList.remove('overflow-hidden')
      return null
    }

    document.body.classList.add('overflow-hidden')
    return createPortal(
      <div className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>,
      backdropPortal
    )
  }

  return (
    <>
      <div ref={drawerRef} className={clsx('fixed top-0 right-0 z-50 h-screen p-10 overflow-y-auto transition-transform bg-white w-[860px] dark:bg-gray-800 md:w-full lg:w-[860px]', { 'transform-none': isOpen, 'translate-x-full': !isOpen })}>
        <button
          className="absolute top-0 right-0 mt-3 mr-2 px-4 py-2 text-xl"
          onClick={onClose}
        >
          <CgClose />
        </button>

        {children}
      </div>

      {renderBackDrop()}
    </>
  )
}
