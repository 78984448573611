import clsx from "clsx";
import { useClickAway } from "@uidotdev/usehooks";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const optionsWithPlaceHolder = (options, placeholder) => {
  if (placeholder) {
    return [{ value: "", text: placeholder }, ...options];
  }

  return options;
};

export const DropdownInput = ({
  className,
  disabled = false,
  icon,
  label,
  onChange,
  options = [],
  placeholder,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useClickAway(() => {
    setIsOpen(false);
  });

  const handleChange = (item) => {
    setIsOpen(false);

    if (onChange) {
      onChange(item.value);
    }
  };

  const handleToggle = () => {
    setIsOpen((state) => !state);
  };

  const classes = {
    root: clsx("relative w-full text-gray-600"),
    label: clsx("mb-2 block text-xs font-semibold dark:text-white", className?.label),
    button: clsx(
      "relative flex items-center bg-slate-50 w-full h-12 px-4 border border-slate-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500",
      className?.button
    ),
    placeholder: clsx(className?.placeholder),
    arrow: clsx("absolute top-4 right-3", className?.arrow),
    dropdownContainer: clsx(
      "absolute z-10 bg-white w-full mb-1 top-[52px] left-0 rounded text-sm shadow-md ring-1 ring-black ring-opacity-5 shadow overflow-y-auto",
      className?.dropdownContainer,
      { block: isOpen, hidden: !isOpen }
    ),
    dropdownItem: clsx("px-4 py-2 hover:bg-slate-100 cursor-default", className?.dropdownItem),
  };

  const displayValue = options.find((item) => item.value === value)?.text;

  return (
    <div ref={dropdownRef} className={classes.root}>
      {Boolean(label) && (
        <label className={classes?.label}>{label}</label>
      )}

      <button onClick={handleToggle} className={classes.button} type="button" disabled={disabled}>
        {icon}

        <span className={classes.placeholder}>
          {value ? displayValue : placeholder}
        </span>

        {isOpen ? (
          <IoIosArrowUp className={classes.arrow} />
        ) : (
          <IoIosArrowDown className={classes.arrow} />
        )}
      </button>

      <div className={classes.dropdownContainer}>
        <ul>
          {optionsWithPlaceHolder(options, placeholder).map((item, index) => (
            <li
              key={`item-${item.value}-${index}`}
              className={classes.dropdownItem}
              onClick={() => handleChange(item)}
            >
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
