export const formatPhoneNumber = (phoneNumber, delimiter = " ") => {
  if (phoneNumber && phoneNumber.length === 11) {
    const cleanedNumber = phoneNumber.replace(/\D/g, "");
    return cleanedNumber.replace(
      /(\d{4})(\d{3})(\d{4})/,
      `$1${delimiter}$2${delimiter}$3`
    );
  }

  return phoneNumber;
};
