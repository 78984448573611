import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
// import Profile from 'views/admin/profile'

import UsersView from "views/admin/users";
import BanksView from "views/admin/banks";
import ClientsView from "views/admin/clients";
import ClientsDetailView from "views/admin/clients/view";
import AccountsView from "views/admin/accounts";
import TransactionsView from "views/admin/transactions";
import InboxView from "views/admin/inbox";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { ImUserTie } from "react-icons/im";
import { AiFillBank, AiOutlineUser } from "react-icons/ai";
import { FaCodeBranch } from "react-icons/fa";
import { FiMail } from 'react-icons/fi'
import { MdGroupWork } from "react-icons/md";

import {
  MdHome,
  MdLock,
  MdSupervisedUserCircle,
  MdMonetizationOn,
} from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Branches",
    layout: "/admin",
    path: "branches",
    icon: <FaCodeBranch className="h-6 w-6" />,
    component: <MainDashboard />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Clients",
    layout: "/admin",
    icon: <MdGroupWork className="h-6 w-6" />,
    path: "clients",
    component: <ClientsView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Banks",
    layout: "/admin",
    icon: <AiFillBank className="h-6 w-6" />,
    path: "banks",
    component: <BanksView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <AiOutlineUser className="h-6 w-6" />,
    path: "users",
    component: <UsersView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Client Details",
    layout: "/admin",
    path: "clients/:id",
    component: <ClientsDetailView />,
    protected: true,
    isInMenu: false,
  },
  {
    name: "Accounts",
    layout: "/admin",
    icon: <MdSupervisedUserCircle className="h-6 w-6" />,
    path: "accounts",
    component: <AccountsView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Transactions",
    layout: "/admin",
    icon: <MdMonetizationOn className="h-6 w-6" />,
    path: "transactions",
    component: <TransactionsView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Inbox",
    layout: "/admin",
    icon: <FiMail className="h-6 w-6" />,
    path: "inbox",
    component: <InboxView />,
    protected: true,
    isInMenu: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    protected: false,
    isInMenu: false,
  },
];

export default routes;
