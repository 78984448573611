import { Controller } from "react-hook-form";
import { TextInput } from "./TextInput";

export const TextField = ({
  className,
  control,
  disabled = false,
  id,
  icon,
  label,
  name,
  placeholder = "",
  value,
  ...props
}) => {
  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      render={({ field }) => {
        return (
          <TextInput
            className={className}
            disabled={disabled}
            id={id}
            label={label}
            name={field.name}
            onChange={field.onChange}
            placeholder={placeholder}
            value={field.value}
            {...props}
          />
        );
      }}
    />
  );
};
