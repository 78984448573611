import { gql } from "graphql-request";

export const FETCH_PRANK_QUERY = gql`
  query FetchPrankQuery {
    prank: fetchPrank {
      prankId
      prankUrl
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    user: currentUser {
      id
      branchId
      userType
      email
      username
      firstName
      lastName
      fullName
      createdAt
      updatedAt
    }
  }
`;

export const FETCH_BANKS_QUERY = gql`
  query FetchBanksQuery {
    banks: fetchBanks {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      totalCount

      edges {
        node {
          id
          name
          accountNumber
          description
          isEnabled
          createdAt

          bankType {
            id
            name
          }

          agents {
            id
            username
            fullName
          }
        }
      }
    }
  }
`;

export const FETCH_CLIENTS_QUERY = gql`
  query FetchClientsQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    clients: fetchClients(
      first: $limit
      after: $after
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      totalCount

      edges {
        node {
          id
          name
          description
          minimumCashIn
          maximumCashIn
          accountCount
          transactionCount
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const FETCH_CLIENT_QUERY = gql`
  query FetchClientQuery($id: ID!) {
    client: fetchClient(id: $id) {
      id
      name
      description
      minimumCashIn
      maximumCashIn
      currency
      isActive
      transactionWebhookUrl
      transactionRedirectUrl
      createdAt
      updatedAt
      accountCount
      transactionCount
      bankCount

      users {
        id
        username
        fullName
        createdAt
      }

      banks {
        id
        name
        accountNumber
        isEnabled

        agents {
          id
          username
          fullName
          createdAt
        }
      }

      agents {
        id
        username
        firstName
        lastName
        fullName
      }
    }
  }
`;

export const FETCH_ACCOUNTS_QUERY = gql`
  query FetchAccountsQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    accounts: fetchAccounts(
      first: $limit
      after: $after
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      totalCount

      edges {
        node {
          id
          name
          mobileNumber
          status
          transactionCount
          createdAt
          updatedAt

          client {
            id
            name
          }
        }
      }
    }
  }
`;

export const FETCH_TRANSACTIONS_QUERY = gql`
  query FetchTransactionsQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    transactions: fetchTransactions(
      first: $limit
      after: $after
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      totalCount
      totalAmount

      edges {
        node {
          id
          transactionType
          status
          statusNotes
          amount
          fee
          accountName
          accountNumber
          referenceCode
          referenceNumber
          isCallbackSent
          callbackStatusCode
          callbackNotes
          clientTransactionId
          clientNotes
          createdAt
          updatedAt

          branch {
            id
            name
          }

          client {
            id
            name
          }

          account {
            id
            name
            mobileNumber
          }

          bank {
            id
            name
            accountNumber
          }
          
          user {
            id
            username
          }
        }
      }
    }
  }
`;

export const FETCH_DASHBOARD_QUERY = gql`
  query FetchDashboardQuery {
    dashboard: fetchDashboard {
      totalTransaction
      totalDeposit
      totalWithdraw
      totalBalance
      totalFee
    }
  }
`;

export const BANK_SELECT_OPTIONS_QUERY = gql`
  query BankSelectOptionsQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    banks: fetchBanks(
      first: $limit
      after: $after
      sort: $sort
      filters: $filters
    ) {
      edges {
        node {
          id
          accountNumber
          name
        }
      }
    }
  }
`;

export const CLIENT_SELECT_OPTIONS_QUERY = gql`
  query FetchClientsSelectOptionsQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    clients: fetchClients(
      first: $limit
      after: $after
      sort: $sort
      filters: $filters
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_INBOX_QUERY = gql`
  query FetchGcashInboxQuery(
    $limit: Int
    $after: String
    $sort: SortInput
  ) {
    inbox: fetchGcashInboxes(
      first: $limit
      after: $after
      sort: $sort
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          bank {
            id
            name
          }
          transactionId
          transactionType
          amount
          accountName
          accountNumber
          balance
          referenceNumber
          msg
          agent
          status
          inboxDatetime
        }
      }
    }
  }
`;

export const FETCH_USERS_QUERY = gql`
  query FetchUsersQuery(
    $limit: Int
    $after: String
    $sort: SortInput
    $filters: [FilterInput!]
  ) {
    users: fetchUsers(
      first: $limit 
      after: $after
      sort: $sort
      filters: $filters
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          userType
          email
          username
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
    }
  }
`