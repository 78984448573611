import { useCurrentUser } from "hooks/use-current-user";
import { formatPhoneNumber } from "lib/formatters";
import { time } from "lib/utils";

export const Table = ({ edges, page, limit }) => {
  const { currentUser } = useCurrentUser();
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  return (
    <div className="mt-4 mb-4 rounded-lg border border-gray-200">
      <table className="min-w-full divide-y-2 divide-gray-200 text-xs">
        <thead className="text-slate-500 ltr:text-left rtl:text-right">
          <tr>
            <th className="w-12 text-center">#</th>

            {isAdmin && (
              <th className="w-32 whitespace-nowrap px-4 py-2 text-center">
                Client
              </th>
            )}

            <th className="w-36 whitespace-nowrap px-4 py-2 text-left">
              AccountID
            </th>

            <th className="whitespace-nowrap px-4 py-2 text-left">Name</th>

            <th className="w-1 whitespace-nowrap px-4 py-2 text-left">
              MobileNumber
            </th>

            <th className="w-20 whitespace-nowrap px-4 py-2 text-center">
              TransactionCount
            </th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-right">
              Created
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 font-mono  text-slate-800">
          {edges.map((edge, index) => {
            return (
              <tr key={edge.node.id}>
                <td className="h-12 whitespace-nowrap px-4 py-3 text-center">
                  {(page - 1) * limit + (index + 1)}
                </td>

                {isAdmin && (
                  <td className="whitespace-nowrap px-4 py-3 text-center">
                    <span className="inline-flex items-center justify-center rounded-full bg-green-100 px-2.5 py-0.5 text-2xs">
                      {edge.node.client.name}
                    </span>
                  </td>
                )}

                <td className="whitespace-nowrap px-4 py-3">
                  <a href="/admin/accounts" className="text-blue-700">
                    {edge.node.id}
                  </a>
                </td>

                <td className="whitespace-nowrap px-4 py-3">
                  {edge.node.name}
                </td>

                <td className="whitespace-nowrap px-4 py-3">
                  {formatPhoneNumber(edge.node.mobileNumber, "-")}
                </td>

                <td className="whitespace-nowrap px-4 py-3 text-center">
                  {edge.node.transactionCount}
                </td>

                <td className="whitespace-nowrap px-4 py-3 text-right text-2xs">
                  {time().to(time(edge.node.createdAt))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
