import { useQuery } from "@tanstack/react-query";
import { graphQLClient } from "graphql/client";
import { CURRENT_USER_QUERY } from "graphql/queries";

export const useCurrentUser = () => {
  const token = localStorage.getItem("token");
  const currentUserQuery = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => {
      if (token) {
        return graphQLClient.request(CURRENT_USER_QUERY).then((data) => {
          return data.user;
        });
      }

      return null;
    },
    onError: (error) => {
      localStorage.setItem("token", "");
      window.location = "/auth/sign-in";
    },
    retry: 1,
    refetchOnWindowFocus: false,
  });

  return {
    currentUser: currentUserQuery.data,
    isLoggedIn: !!currentUserQuery.data,
    isLoading: currentUserQuery.isLoading,
  };
};
