import { useQuery } from "@tanstack/react-query";
import { encode } from "js-base64";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { graphQLClient } from "graphql/client";
import { FETCH_ACCOUNTS_QUERY, FETCH_CLIENTS_QUERY } from "graphql/queries";

import { useCurrentUser } from "hooks/use-current-user";

import Card from "components/card";
import { Form, SelectField, TextField } from "components/form";
import { Pagination } from "components/pagination";
import { TableLoader } from "components/table";
import { Table } from "./components/Table";

const LIMIT = 20;
const DEFAULT_QUERY_VARIABLES = {
  limit: LIMIT,
  after: encode(0, true),
  sort: {
    field: "id",
    direction: "DESC",
  },
  filters: [],
};
const DEFAULT_FILTER_FORM_VALUES = {
  search: "",
  client: "",
  type: "",
  status: "",
  sort: "DESC",
};
const SORTING_OPTIONS = [
  {
    value: "DESC",
    text: "Newest First",
  },
  {
    value: "ASC",
    text: "Oldest First",
  },
];

const AccountsView = () => {
  const { currentUser } = useCurrentUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [queryVariables, setQueryVariables] = useState(DEFAULT_QUERY_VARIABLES);
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  const accountsQuery = useQuery({
    queryKey: ["accounts", queryVariables],
    queryFn: () => {
      return graphQLClient
        .request(FETCH_ACCOUNTS_QUERY, queryVariables)
        .then((data) => {
          return data.accounts;
        });
    },
  });

  const searchForm = useForm({
    defaultValues: DEFAULT_FILTER_FORM_VALUES,
  });

  const handleClearSearch = () => {
    searchForm.reset();
    setQueryVariables(DEFAULT_QUERY_VARIABLES);
  };

  const handleSearchSubmit = (data) => {
    let sort = {
      field: "id",
      direction: "DESC",
    };

    const filters = [];

    if (data.search) {
      filters.push({
        type: "search",
        value: data.search,
      });
    }

    if (data.client) {
      filters.push({
        type: "client",
        value: [data.client],
      });
    }

    if (data.sort) {
      sort = {
        field: "id",
        direction: data.sort,
      };
    }

    setCurrentPage(1);
    setQueryVariables({
      limit: LIMIT,
      after: encode(0, true),
      sort,
      filters,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setQueryVariables((prevState) => ({
      ...prevState,
      after: encode((page - 1) * LIMIT, true),
    }));
  };

  const clientsQuery = useQuery({
    queryKey: ["clients"],
    queryFn: () => {
      return graphQLClient
        .request(FETCH_CLIENTS_QUERY, {
          limit: LIMIT,
          after: encode(0, true),
          sort: {
            field: "id",
            direction: "ASC",
          },
          filters: [],
        })
        .then((data) => {
          return data.clients;
        });
    },
  });

  // for client dropdown
  let clientOptions = [];
  if (isAdmin) {
    clientOptions =
      !clientsQuery.isLoading && clientsQuery.data?.edges
        ? clientsQuery.data.edges.map((item) => ({
            value: item.node.id,
            text: item.node.name,
          }))
        : [];
  }

  return (
    <div>
      <div className="mt-5 grid h-full">
        <Card extra={"w-full h-full p-4"}>
          <div className="flex justify-end py-4">
            <Form
              className="flex items-end"
              onSubmit={searchForm.handleSubmit(handleSearchSubmit)}
            >
              <div className="mr-4 w-[260px]">
                <TextField
                  control={searchForm.control}
                  label="Search"
                  name="search"
                />
              </div>

              {isAdmin && (
                <div className="mr-4 w-[120px]">
                  <SelectField
                    control={searchForm.control}
                    name="client"
                    label="Client"
                    options={[{ value: "", text: "All" }, ...clientOptions]}
                  />
                </div>
              )}

              <div className="mr-4">
                <SelectField
                  control={searchForm.control}
                  name="sort"
                  label="Sorting"
                  options={SORTING_OPTIONS}
                />
              </div>

              <div className="flex h-[50px]">
                <button
                  type="button"
                  className="ease focus:shadow-outline mr-2 select-none rounded-full border border-red-500 bg-red-500 px-4 py-3 text-white transition duration-500 hover:bg-red-600 focus:outline-none disabled:border-gray-400 disabled:bg-gray-400"
                  disabled={
                    accountsQuery.isRefetching || accountsQuery.isLoading
                  }
                  onClick={handleClearSearch}
                  title="Clear Search"
                >
                  <RxReset />
                </button>

                <button
                  type="submit"
                  className="ease focus:shadow-outline mr-2 select-none rounded-full border border-brand-500 bg-brand-500 px-4 py-3 text-white transition duration-500 hover:bg-brand-600 focus:outline-none disabled:border-gray-400 disabled:bg-gray-400"
                  disabled={accountsQuery.isLoading}
                  title="Search"
                >
                  <BsSearch />
                </button>
              </div>
            </Form>
          </div>

          {accountsQuery.isLoading && (
            <TableLoader rows={LIMIT} className="mt-[49px] mb-[50px]" />
          )}

          {accountsQuery.isFetched && (
            <Table
              edges={accountsQuery.data.edges}
              page={currentPage}
              limit={LIMIT}
            />
          )}

          {accountsQuery.isFetched && (
            <Pagination
              activePage={currentPage}
              pages={Math.ceil(accountsQuery.data.totalCount / LIMIT)}
              shownPages={5}
              totalCount={accountsQuery.data.totalCount}
              onPageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default AccountsView;
