import clsx from "clsx";
import { useEffect, useState } from "react";
import { TfiDownload, TfiUpload } from "react-icons/tfi";
import { RiUserFollowLine, RiUserSettingsLine } from "react-icons/ri";

import { useCurrentUser } from "hooks/use-current-user";
import { formatDecimal, formatPhoneNumber, formatReference } from "lib/formatters";
import { decode, time } from "lib/utils";

export const CellWithClipboard = ({ className, children, data }) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleCopyToClipboard = async (evt, data) => {
    if (evt.detail === 2) {
      setShowPopup(true)
      await navigator.clipboard.writeText(data)

      setTimeout(() => {
        setShowPopup(false)
      }, 1500);
    }
  }

  const tooltipClass = clsx('absolute bg-gray-600 w-[52px] ml-[26px] text-white rounded-lg text-xs p-1 transition-opacity duration-300 transform -translate-y-7', {
    'opacity-100 visible': showPopup,
    'opacity-0 invisible': !showPopup
  })

  return (
    <td className={className} onClick={(evt) => handleCopyToClipboard(evt, data)}>
      {showPopup && (
        <div className={tooltipClass}>
          copied
          <div className="absolute z-[-1] w-4 h-4 bg-gray-600 transform rotate-45 -translate-x-2/4 bottom-0 left-1/2" />
        </div>
      )}
      {children}
    </td>
  )
}

export const Table = ({ edges, page, limit, onTransactionClick }) => {
  const { currentUser } = useCurrentUser();
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  useEffect(() => { }, []);

  const handleTransactionClick = (item) => {
    if (onTransactionClick) {
      onTransactionClick(item);
    }
  };

  return (
    <div className="mt-4 mb-4 rounded-lg border border-gray-200">
      <table className="min-w-full divide-y-2 divide-gray-200 text-xs">
        <thead className="text-slate-500 ltr:text-left rtl:text-right">
          <tr>
            <th className="w-12 text-center">#</th>

            {isAdmin && (
              <th className="w-12 whitespace-nowrap px-4 py-2 text-center">
                Branch
              </th>
            )}

            {isAdmin && (
              <th className="w-12 whitespace-nowrap px-4 py-2 text-center">
                Client
              </th>
            )}

            <th className="w-40 whitespace-nowrap px-4 py-2 text-center">
              TransactionId
            </th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-center">
              ClientTransactionId
            </th>

            <th className=""></th>

            <th className="w-24 whitespace-nowrap px-4 py-2 text-center">
              Type
            </th>

            <th className="w-24 whitespace-nowrap px-4 py-2 text-center">
              ReferenceCode
            </th>

            <th className="w-26 whitespace-nowrap px-4 py-2 text-center">
              ReferenceNumber
            </th>

            <th className="w-46 whitespace-nowrap py-2 pl-9 pr-4 text-left">
              AccountName
            </th>

            <th className="w-32 whitespace-nowrap py-2 pl-9 pr-4 text-left">
              AccountNumber
            </th>

            <th className="w-22 whitespace-nowrap px-4 py-2 text-right">
              Amount
            </th>

            <th className="w-22 whitespace-nowrap px-4 py-2 text-right">
              Fee
            </th>

            <th className="w-28 whitespace-nowrap px-4 py-2 text-center">
              Status
            </th>

            <th className="w-28 whitespace-nowrap py-2 pl-1 pr-4 text-right">
              Created
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 font-mono text-slate-800">
          {edges.map((edge, index) => {
            const isExpired =
              ["INITIAL", "IN_PROGRESS"].includes(edge.node.status) &&
              time().diff(time(edge.node.createdAt), "minute") > 60;

            return (
              <tr
                key={edge.node.id}
                className={clsx("hover:bg-slate-100", {
                  "bg-red-100": isExpired,
                })}
              >
                <td className="h-12 whitespace-nowrap px-4 py-2 text-center text-2xs">
                  {(page - 1) * limit + (index + 1)}
                </td>

                {isAdmin && (
                  <td className="whitespace-nowrap px-4 py-2 text-center">
                    <span
                      className={clsx("inline-flex items-center justify-center rounded-full  px-2.5 py-0.5 text-2xs", {
                        'bg-emerald-100 text-emerald-700': edge.node.branch.name.toLowerCase() === 'subic',
                        'bg-purple-100 text-purple-700': edge.node.branch.name.toLowerCase() === 'bataan',
                        'bg-red-100 text-red-700': edge.node.branch.name.toLowerCase() === 'sanfelipe'
                      })}
                    >
                      {edge.node.branch.name.toLowerCase()}
                    </span>
                  </td>
                )}

                {isAdmin && (
                  <td className="whitespace-nowrap px-4 py-2 text-center">
                    <span className="inline-flex items-center justify-center rounded-full bg-green-100 px-2.5 py-0.5 text-2xs">
                      {edge.node.client.name}
                    </span>
                  </td>
                )}

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  <button
                    className="text-blue-700"
                    onClick={() => handleTransactionClick(edge.node)}
                  >
                    {edge.node.id}
                  </button>
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  {edge.node.clientTransactionId}
                </td>

                <td></td>

                <td className="whitespace-nowrap px-4 py-2 text-center text-navy-700">
                  <span
                    className={clsx(
                      "inline-flex cursor-pointer items-center justify-center rounded-full px-2.5 py-0.5 text-2xs",
                      {
                        "bg-emerald-100 text-emerald-700":
                          edge.node.transactionType === "DEPOSIT",
                        "bg-amber-100 text-amber-700":
                          edge.node.transactionType === "WITHDRAW",
                      }
                    )}
                    onClick={() => handleTransactionClick(edge.node)}
                  >
                    {edge.node.transactionType === "DEPOSIT" && <TfiUpload />}
                    {edge.node.transactionType === "WITHDRAW" && (
                      <TfiDownload />
                    )}

                    <p className="ml-1 whitespace-nowrap">
                      {edge.node.transactionType}
                    </p>
                  </span>
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  {edge.node.referenceCode}
                </td>

                <CellWithClipboard className="relative whitespace-nowrap px-4 py-2 text-center cursor-pointer select-none" data={edge.node.referenceNumber}>
                  {formatReference(edge.node.referenceNumber)}
                </CellWithClipboard>

                <td
                  className="whitespace-nowrap px-4 py-2 text-left"
                  title={
                    edge.node.accountName === edge.node.account.name
                      ? "Main Account Name"
                      : "Custom Account Name"
                  }
                >
                  <div className="align-center flex">
                    {edge.node.accountName === edge.node.account.name ? (
                      <RiUserFollowLine className="inline-block text-base text-emerald-600" />
                    ) : (
                      <RiUserSettingsLine className="inline-block text-base text-cyan-600" />
                    )}
                    <span className="align-center ml-2 inline-block w-40 truncate">
                      {edge.node.accountName}
                    </span>
                  </div>
                </td>

                <CellWithClipboard className="relative whitespace-nowrap px-4 py-2 text-center cursor-pointer select-none" data={edge.node.accountNumber}>
                  <>
                    {edge.node.accountNumber === edge.node.account.mobileNumber ? (
                      <RiUserFollowLine className="inline-block text-base text-emerald-600" />
                    ) : (
                      <RiUserSettingsLine className="inline-block text-base text-cyan-600" />
                    )}
                    <span className="ml-2">
                      {formatPhoneNumber(edge.node.accountNumber, "-")}
                    </span>
                  </>
                </CellWithClipboard>

                <td className="whitespace-nowrap px-4 py-2 text-right">
                  {formatDecimal(edge.node.amount)}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-right">
                  {formatDecimal(edge.node.fee)}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-center">
                  <span
                    className={clsx(
                      "inline-flex cursor-pointer select-none items-center justify-center rounded-full px-2.5 py-0.5 text-2xs",
                      {
                        "bg-purple-100 text-purple-700":
                          edge.node.status === "INITIAL",
                        "bg-blue-100 text-blue-500":
                          edge.node.status === "IN_PROGRESS",
                        "bg-emerald-100 text-emerald-700":
                          edge.node.status === "SUCCESS",
                        "bg-red-100 text-red-700": [
                          "FAILED",
                          "CANCELLED",
                          "REFUNDED",
                        ].includes(edge.node.status),
                      }
                    )}
                    onClick={() => handleTransactionClick(edge.node)}
                  >
                    <p className="ml-1 whitespace-nowrap">{edge.node.status}</p>
                  </span>
                </td>

                <td className="whitespace-nowrap px-2 py-2 text-right text-2xs" title={time(edge.node.createdAt).format("MMM.DD hh:mm A")}>
                  {time().to(time(edge.node.createdAt))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
