import clsx from "clsx";
import Datepicker from "react-tailwindcss-datepicker";

export const DateRangeInput = ({
  className,
  id,
  icon,
  format = 'MMM DD YYYY',
  label,
  name,
  onChange,
  placeholder = '',
  value
}) => {
  const inputId = Boolean(id) ? id : name;

  return (
  <div className={clsx(className?.container, "text-gray-600")}>
    {Boolean(label) && (
      <label
        htmlFor={inputId}
        className={clsx(
          className?.label,
          "mb-2 block text-xs font-semibold dark:text-white"
        )}
      >
        {label}
      </label>
    )}

    {icon}

    <div>
      <Datepicker
        placeholder={placeholder}
        separator={"-"}
        showShortcuts={true}
        useRange={false}
        value={value}
        displayFormat={format}
        onChange={onChange}
        inputClassName={clsx('block bg-slate-50 w-full h-12 px-4 py-3 border border-slate-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500', className?.input)}
        toggleClassName="absolute top-[14px] right-3 text-slate-500"
      />
    </div>
  </div>
  )
}