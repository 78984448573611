import clsx from "clsx";

export const TableLoader = ({ className, rows }) => {
  const items = [];

  for (let i = 0; i < rows; i++) {
    const isEven = i % 2 === 0;
    items.push(
      <div
        key={`loading-row-${i}`}
        className={clsx("mb-3 h-[36px] rounded", {
          "bg-gray-100": isEven,
          "bg-gray-200": !isEven,
        })}
      ></div>
    );
  }

  return <div className={clsx(className, "animate-pulse")}>{items}</div>;
};
