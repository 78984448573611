export const styles = {
  root: 'block transition-none',
  label: 'mb-2 block text-xs text-gray-600 font-semibold dark:text-white ',
  input:
    'block w-full bg-gray-50 rounded-lg border-1 border-slate-200 text-gray-600 font-semibold focus:border-blue-500 focus:ring-blue-500',
  inputContainer: 'relative mt-2 rounded-md',
  inputIconContainer: 'absolute inset-y-0 right-0 flex items-center pr-3'
}

export const sizes = {
  base: 'text-sm py-3 leading-6',
  small: 'text-xs'
}
