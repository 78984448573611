import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { encode } from "js-base64";

import {
  FaDownload,
  FaExchangeAlt,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
  FaTags,
  FaSimCard
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiShieldUserFill } from "react-icons/ri";
import { RxReset } from "react-icons/rx";
import { SlRefresh } from "react-icons/sl";
import { LuSettings2 } from "react-icons/lu";

import { graphQLClient } from "graphql/client";
import { CLIENT_SELECT_OPTIONS_QUERY, BANK_SELECT_OPTIONS_QUERY } from "graphql/queries";


import { useCurrentUser } from "hooks/use-current-user";
import { decode } from "lib/utils";
import { formatPhoneNumber } from "lib/formatters";
import { DropdownInput, DateRangeInput, TextInput } from "components/form";

const TRANSACTION_TYPE_OPTIONS = [
  { text: "Deposit", value: "DEPOSIT" },
  { text: "Withdrawal", value: "WITHDRAW" },
];

const STATUS_OPTIONS = [
  {
    value: "INITIAL",
    text: "Initial",
  },
  {
    value: "IN_PROGRESS",
    text: "In Progress",
  },
  {
    value: "SUCCESS",
    text: "Success",
  },

  {
    value: "REFUNDED",
    text: "Refunded",
  },
  {
    value: "CANCELLED",
    text: "Cancelled",
  },
  {
    value: "FAILED",
    text: "Failed",
  },
];

const SEARCH_TYPE_OPTIONS = [
  { text: "Reference Code", value: "reference_code" },
  { text: "Reference Number", value: "reference_number" },
  { text: "Account Number", value: "account_number" },
  { text: "Account Name", value: "account_name" },
  { text: "Transaction ID", value: "transaction_id" },
  { text: "Client Transaction ID", value: "client_transaction_id" },
  { text: "Amount", value: "amount" },
];

const DEFAULT_FILTER_VALUE = {
  client: "",
  type: "",
  status: "",
  date: {
    startDate: null,
    endDate: null,
  },
  searchType: "reference_code",
  search: "",
  bank: "",
  sort: {
    field: "id",
    direction: "DESC",
  },
};

export const SearchBar = ({
  isLoading = false,
  onRefresh,
  onSearch,
  onExport,
}) => {
  const { currentUser } = useCurrentUser();
  const [filters, setFilters] = useState(DEFAULT_FILTER_VALUE);
  const debouncedSearch = useDebounce(filters.search, 300);

  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  // useEffect(() => {
  //   const newState = { ...filters, search: debouncedSearch };
  //   setFilters(newState);
  //   onSearch(newState);
  // }, [debouncedSearch]);

  // client select options
  const clientsQuery = useQuery({
    queryKey: ["clients:dropdown"],
    queryFn: () => {
      return graphQLClient
        .request(CLIENT_SELECT_OPTIONS_QUERY, {
          limit: 10000,
          after: encode(0, true),
          sort: {
            field: "name",
            direction: "ASC",
          },
          filters: [],
        })
        .then((data) => {
          return data.clients;
        });
    },
  });
  const clientSelectOptions = !clientsQuery.isLoading && clientsQuery.data?.edges
    ? clientsQuery.data.edges.map((item) => ({
      value: item.node.id,
      text: item.node.name,
    }))
    : [];

  // bank select options
  const bankSelectOptionsQuery = useQuery({
    queryKey: ["banks:dropdown"],
    queryFn: () => {
      return graphQLClient
        .request(BANK_SELECT_OPTIONS_QUERY, {
          limit: 10000,
          after: encode(0, true),
          sort: {
            field: "id",
            direction: "ASC",
          },
          filters: [],
        })
        .then((data) => {
          return data.banks;
        });
    },
  });
  const bankSelectOptions =
    !bankSelectOptionsQuery.isLoading && bankSelectOptionsQuery.data?.edges
      ? bankSelectOptionsQuery.data.edges.map((item) => ({
        value: item.node.id,
        text: `${formatPhoneNumber(item.node.accountNumber, '-')} - [${item.node.name}]`,
      }))
      : [];

  const handleClientChange = (value) => {
    const newState = { ...filters, client: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleTransactionTypeChange = (value) => {
    const newState = { ...filters, type: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleStatusChange = (value) => {
    const newState = { ...filters, status: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleDateChange = (value) => {
    const newState = { ...filters, date: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleWalletChange = (value) => {
    const newState = { ...filters, bank: value };
    setFilters(newState);
    onSearch(newState);
  }

  const handleSearchTypeChange = (value) => {
    const newState = { ...filters, searchType: value };
    setFilters(newState);
    onSearch(newState);
  };

  const handleSearchTextChange = (evt) => {
    setFilters((state) => ({ ...state, search: evt.target.value }));
  };

  const handleSearchTextEnterKey = (evt) => {
    if (evt.key === "Enter") {
      onSearch(filters);
    }
  };

  const handleClearSearchText = () => {
    const newState = { ...filters, search: "" };
    setFilters(newState);
    onSearch(newState);
  };

  const handleClearFilter = () => {
    setFilters(DEFAULT_FILTER_VALUE);
    onSearch(DEFAULT_FILTER_VALUE);
  };

  const handleToggleSort = () => {
    const newState = {
      ...filters,
      sort: {
        field: filters.sort.field,
        direction: filters.sort.direction === "ASC" ? "DESC" : "ASC",
      },
    };
    setFilters(newState);
    onSearch(newState);
  };

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex border border-slate-200 bg-slate-50">
        {isAdmin && (
          <div className="w-[220px] border border-y-0 border-l-0 border-slate-200">
            <DropdownInput
              icon={<RiShieldUserFill className="mr-2 text-2xl text-slate-500" />}
              placeholder="All Client"
              options={clientSelectOptions}
              onChange={handleClientChange}
              className={{
                button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
              }}
              value={filters.client}
            />
          </div>
        )}

        <div className="w-40 border border-y-0 border-l-0 border-slate-200">
          <DropdownInput
            icon={<FaExchangeAlt className="mr-2 text-2xl text-slate-500" />}
            placeholder="All Types"
            options={TRANSACTION_TYPE_OPTIONS}
            onChange={handleTransactionTypeChange}
            className={{
              button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
            }}
            value={filters.type}
          />
        </div>

        <div className="w-40 border border-y-0 border-l-0 border-slate-200">
          <DropdownInput
            icon={<FaTags className="mr-2 text-2xl text-slate-500" />}
            placeholder="All Status"
            options={STATUS_OPTIONS}
            onChange={handleStatusChange}
            className={{
              button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
            }}
            value={filters.status}
          />
        </div>

        <div className="w-[260px]">
          <DateRangeInput
            onChange={handleDateChange}
            value={filters.date}
            placeholder="MM DD YYYY - MM DD YYYY"
            className={{
              input:
                "rounded-sm border-0 bg-transparent focus:bg-slate-200 focus:ring-0",
            }}
          />
        </div>

        {isAdmin && (
          <div className="w-[280px]">
            <DropdownInput
              icon={<FaSimCard className="mr-2 text-xl text-slate-500" />}
              placeholder="All Wallet"
              options={bankSelectOptions}
              onChange={handleWalletChange}
              className={{
                button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
                placeholder: "font-mono text-xs",
                dropdownContainer: "max-h-[260px] overflow-y-auto",
                dropdownItem: 'font-mono text-xs'
              }}
              value={filters.bank}
            />
          </div>
        )}

        <div className="flex grow justify-end">
          <button
            type="button"
            className="flex h-12 w-[50px] items-center justify-center bg-slate-600 text-xl text-white disabled:bg-gray-400"
            title="Export Data"
            disabled={isLoading}
            onClick={onExport}
          >
            <FaDownload />
          </button>
        </div>
      </div>

      <div className="flex grow border border-slate-200 bg-slate-50">
        <div className="w-[220px] border border-y-0 border-l-0 border-slate-200">
          <DropdownInput
            icon={<LuSettings2 className="mr-2 text-2xl text-slate-500" />}
            options={SEARCH_TYPE_OPTIONS}
            onChange={handleSearchTypeChange}
            className={{
              button: "rounded-sm border-0 bg-transparent hover:bg-slate-200",
            }}
            value={filters.searchType}
          />
        </div>

        <div className="grow">
          <TextInput
            className={{
              input:
                "rounded-sm border-0 bg-transparent focus:bg-slate-200 focus:ring-0",
            }}
            icon={<FaSearch className="mr-2 text-base text-slate-500" />}
            iconRight={
              <MdClose
                onClick={handleClearSearchText}
                title="Clear Search"
                className="absolute top-[16px] right-2 cursor-pointer text-base text-slate-500"
              />
            }
            onChange={handleSearchTextChange}
            onKeyDown={handleSearchTextEnterKey}
            value={filters.search}
          />
        </div>

        <div className="flex">
          <button
            type="button"
            className="flex h-12 w-[50px] items-center justify-center bg-red-500 text-xl text-white"
            title="Reset Filters"
            onClick={handleClearFilter}
          >
            <RxReset />
          </button>

          <button
            type="button"
            className="flex h-12 w-[50px] items-center justify-center bg-teal-500 text-xl text-white disabled:bg-gray-400"
            title="Refresh Data"
            onClick={onRefresh}
            disabled={isLoading}
          >
            <SlRefresh />
          </button>

          <button
            type="button"
            className="flex h-12 w-[50px] items-center justify-center bg-slate-600 text-xl text-white disabled:bg-gray-400"
            title="Sorting"
            onClick={handleToggleSort}
            disabled={isLoading}
          >
            {filters.sort.direction === "ASC" ? (
              <FaSortAmountDown />
            ) : (
              <FaSortAmountUp />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
