import { formatPhoneNumber } from "lib/formatters";
import { useCurrentUser } from "hooks/use-current-user";
import { decode, time } from "lib/utils";

export const Table = ({ edges }) => {
  const { currentUser } = useCurrentUser();
  const isAdmin = ["ROOT", "ADMIN", "AGENT"].includes(currentUser.userType);

  return (
    <div className="mt-4 mb-4 rounded-lg border border-gray-200">
      <table className="min-w-full divide-y-2 divide-gray-200 text-xs">
        <thead className="text-slate-500 ltr:text-left rtl:text-right">
          <tr>
            <th className="w-12 text-center">#</th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-left">
              UserID
            </th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-left">
              UserType
            </th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-left">
              Username
            </th>

            <th className="w-40 whitespace-nowrap px-4 py-2 text-left">
              Name
            </th>

            <th className="whitespace-nowrap px-4 py-2 text-left">
              Email
            </th>

            <th className="w-28 whitespace-nowrap py-2 pl-1 pr-4 text-right">
              Created
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 font-mono  text-slate-800">
          {edges.map((edge) => {
            return (
              <tr key={edge.node.id}>
                <td className="h-12 whitespace-nowrap px-4 py-2 text-center text-2xs">
                  #
                </td>

                {isAdmin && (
                  <td className="whitespace-nowrap px-4 py-2 text-left">
                    <a href="/admin/users" className="text-blue-700">
                      {edge.node.id}
                    </a>
                  </td>
                )}

                {!isAdmin && (
                  <td className="whitespace-nowrap px-4 py-2 text-left">
                    <a href="/admin/users" className="text-blue-700">
                      {edge.node.id}
                    </a>
                  </td>
                )}

                {isAdmin && (
                  <td className="whitespace-nowrap px-4 py-2 text-left">
                    {['ADMIN', 'AGENT'].includes(edge.node.userType) && (
                      <span className="inline-flex cursor-pointer items-center justify-center rounded-full px-2.5 py-0.5 text-2xs bg-emerald-100 text-emerald-700">
                        {edge.node.userType.toLowerCase()}
                      </span>
                    )}

                    {edge.node.userType === 'CLIENT' && (
                      <span className="inline-flex cursor-pointer items-center justify-center rounded-full px-2.5 py-0.5 text-2xs bg-blue-100 text-blue-700">
                        {edge.node.userType.toLowerCase()}
                      </span>
                    )}
                  </td>
                )}

                <td className="whitespace-nowrap px-4 py-2 text-left">
                  {edge.node.username}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-left">
                  {`${edge.node.firstName} ${edge.node.lastName}`}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-left">
                  {edge.node.email}
                </td>

                <td className="whitespace-nowrap px-2 py-2 text-right text-2xs" title={time(edge.node.createdAt).format("MMM.DD hh:mm A")}>
                  {time().to(time(edge.node.createdAt))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
