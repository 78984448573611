import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'

import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Form } from 'components/form'
import { Loader } from 'components/loader'

import { useCurrentUser } from 'hooks/use-current-user'
import { graphQLClient } from 'graphql/client'
import { LOGIN_MUTATION } from 'graphql/mutations'

export default function SignIn() {
  const { isLoggedIn, isLoading } = useCurrentUser()

  const form = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const loginMutation = useMutation({
    mutationFn: (input) => {
      return graphQLClient.request(LOGIN_MUTATION, { input }).then(data => {
        return data.login.token
      })
    },
    onSuccess: (token) => {
      localStorage.setItem('token', token)

      setTimeout(() => {
        window.location = '/admin/default'
      }, 100)
    },
  })

  const handleSubmit = (input) => {
    loginMutation.mutate(input)
  }

  if (isLoading) {
    return <Loader withBackdrop />
  }

  if (isLoggedIn) {
    return <Navigate to="/" replace />
  }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>

        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          {/* Email */}
          <InputField
            variant="auth"
            type="text"
            name="username"
            className={{
              root: 'mb-3'
            }}
            label="Username"
            placeholder="Enter your username"
            control={form.control}
            validation={{
              required: {
                value: true,
                message: "username is required"
              }
            }}
          />

          {/* Password */}
          <InputField
            variant="auth"
            type="password"
            name="password"
            className={{
              root: 'mb-3'
            }}
            label="Password"
            placeholder="Enter your password"
            control={form.control}
            validation={{
              required: {
                value: true,
                message: "password is required"
              }
            }}
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>
          <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </Form>

      </div>
    </div>
  );
}
